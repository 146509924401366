// Copyright 2021 SeekOps Inc.
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

// first party
import axiosInstance from "../../AJAX";
import { AuthenticationInitialPayload } from "./Authentication.interfaces";
import preferencesActionTypes, {
  emissionsPreferences,
  flightpathPreferences,
  UserUnitsITF,
} from "../../store/preferences/preferences.types";
import { ThemeType } from "../../config/theme";
import { AbilityContext } from "../../Can";
import { RootState } from "../../store";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem
} from "../../utils/LocalStorage.utils";
import { notificationActionTypes } from "../../store/notification";

// i18n
import { LanguageCode, loadTranslations } from "../../config/i18n";
import EN_US from "./ResourceBundles/en_US.json";
import AR_SA from "./ResourceBundles/ar_SA.json";
import ES_MX from "./ResourceBundles/es_MX.json";
import ZH_CN from "./ResourceBundles/zh_CN.json";
import FR_FR from "./ResourceBundles/fr_FR.json";
import IT_IT from "./ResourceBundles/it_IT.json";
import PT_BR from "./ResourceBundles/pt_BR.json";
import RU_RU from "./ResourceBundles/ru_RU.json";

const useAuthentication = () => {
  const dispatch = useDispatch();
  const abilitiesContext = useContext(AbilityContext);
  const [hasError, setHasError] = useState<boolean>(false);
  const authURL = "/token/create";
  const MSALToken = useSelector(
    (state: RootState) => state.authentication.MSALAccessToken
  );

  const { instance } = useMsal();

  const { t } = useTranslation();

  useEffect(() => {
    loadTranslations([
      { code: LanguageCode.ENUS, file: EN_US },
      { code: LanguageCode.ARSA, file: AR_SA },
      { code: LanguageCode.ESMX, file: ES_MX },
      { code: LanguageCode.FRFR, file: FR_FR },
      { code: LanguageCode.ITIT, file: IT_IT },
      { code: LanguageCode.PTBR, file: PT_BR },
      { code: LanguageCode.RURU, file: RU_RU },
      { code: LanguageCode.ZHCN, file: ZH_CN },
    ]);
  }, []);

  /**
   *
   * @param abilities
   */
  const initializeAbilities = (abilities: any) => {
    let rules = [];
    for (const ability in abilities) {
      if (abilities.hasOwnProperty(ability)) {
        const element: string[] = abilities[ability];
        rules.push({ action: element, subject: ability });
      }
    }

    abilitiesContext.update(rules);
  };

  const setAbilities = (abilities: any) => {
    dispatch({
      type: "@@authorization/SET_ABILITIES",
      abilities: abilities,
    });
  };

  /**
   * Stores authorization data (abilities) after successful sign in
   *
   * @param authorizationData response data from successful sign in (token
   * creation) which can contain abilities (permissions)
   */
  const storeAuthorizationData = (authorizationData: any) => {
    if (authorizationData?.company_role?.role?.abilities) {
      setAbilities(authorizationData.company_role.role.abilities);
    }
  };

  /**
   * Stores authentication tokens after successful sign in
   *
   * @param authenticationData response data from successful sign in (token
   * creation)
   */
  const storeAuthenticationData = (authenticationData: any) => {
    // dispatch authentication procedure
    dispatch({
      type: "@@authentication/INITIALIZE_SUCCESSFUL",
      token: authenticationData.access,
      refreshToken: authenticationData.refresh,
      refreshExpiry: authenticationData.refresh_token_expiry,
      userIDNumber: authenticationData.id,
      userID: authenticationData.username,
      userNameFirst: authenticationData.first_name,
      userNameLast: authenticationData.last_name,
      companyID: authenticationData.company_role.company.id,
      companyName: authenticationData.company_role.company.name,
      companies: authenticationData.companies
        ? authenticationData.companies
        : [
            {
              id: authenticationData.company_id,
              name: authenticationData.company,
            },
          ],
      eulaAccepted: authenticationData.eula_accepted,
    });
  };

  const setEmissionsPreferences = (
    emissionsPreferences: emissionsPreferences
  ) => {
    dispatch({
      type: preferencesActionTypes.SET_EMISSIONS_PREFERENCES,
      emissionsPreferences: emissionsPreferences,
    });
  };

  const setFlightpathPreferences = (
    flightpathPreferences: flightpathPreferences
  ) => {
    dispatch({
      type: preferencesActionTypes.SET_FLIGHTPATH_PREFERENCES,
      flightpathPreferences: flightpathPreferences,
    });
  };

  const setUserUnits = (userUnits: UserUnitsITF) => {
    dispatch({
      type: preferencesActionTypes.SET_USER_UNITS,
      userUnits: userUnits,
    });
  };

  const setThemeType = (themeType: string) => {
    document.body.className = themeType;
    dispatch({
      type: "@@theme/SET_THEME_TYPE",
      themeType: themeType,
    });
  };

  const setLocale = (newLocale: string, newDir: string) => {
    dispatch({
      type: "@@languageSelectorStore/SET_LOCALE",
      locale: newLocale,
      dir: newDir,
    });
  };

  const setSessionExpired = (isExpired: boolean) => {
    dispatch({
      type: "@@authentication/SET_SESSION_EXPIRED",
      signedOutAutomatically: isExpired,
    });
  };

  const signOut = () => {
    setSessionExpired(true);

    dispatch({
      type: "@@authentication/SIGN_OUT",
    });

    dispatch({
      type: notificationActionTypes.SET_INFO_NOTIFICATION_OPEN,
      origin: "Authentication",
      message: t("msg.login.refresh-expired"),
    });
    
    const MSALlogout = (msalInstance: IPublicClientApplication) => {
      if (MSALToken && msalInstance) {
        msalInstance.logoutPopup();
      }
    };

    MSALlogout(instance);
  };

  /**
   *
   * @param username
   * @param password
   */
  const authenticate = (credentials?: {
    username: string;
    password: string;
  }) => {
    const reAuthRequired = getLocalStorageItem("reauth_required")
  
    if (reAuthRequired) {
      signOut();
    } else {
      // reset any previous error
      setHasError(false);
      // legacy login
      let legacyCredentials: AuthenticationInitialPayload | {} = {};
      // SSO login
      let SSOCredentials: { azure_token: string } | {} = {};
      if (MSALToken) {
        SSOCredentials = { azure_token: MSALToken };
      } else if (credentials) {
        legacyCredentials = {
          username: credentials.username,
          password: credentials.password,
        };
      }
  
      const payload = { ...legacyCredentials, ...SSOCredentials };
  
      axiosInstance
        .post(authURL, payload)
        .then(async (response: any) => {
          initializeAbilities(response.data.company_role.role.abilities);
          const authHeader = `Bearer ${response.data.access}`;
          if (authHeader) {
            // update the headers in axios instance for subsequent requests
            axiosInstance.defaults.headers.common["Authorization"] = authHeader;
            setLocalStorageItem("JWT", authHeader);
          }
          storeAuthenticationData(response.data);
          storeAuthorizationData(response.data);
  
          let preferences;
          let emissionsPreferences: emissionsPreferences;
          let flightpathPreferences: flightpathPreferences;
          if (response?.data?.company_role?.preferences) {
            preferences = response.data.company_role.preferences;
            if (preferences?.emissions) {
              emissionsPreferences = preferences.emissions;
              setEmissionsPreferences(emissionsPreferences);
            }
            if (preferences?.flightPath) {
              flightpathPreferences = preferences.flightPath;
              setFlightpathPreferences(flightpathPreferences);
            }
            // store user units
            if (preferences?.unit_system && preferences?.emissions?.unit) {
              const userUnits: UserUnitsITF = {
                systemUnit: preferences.unit_system,
                flowRateUnit: preferences.emissions.unit,
              };
              setUserUnits(userUnits);
            }
            // sync offline preferences if user set it before login
            const localeOffline = getLocalStorageItem("offline_locale");
            const themeOffline = getLocalStorageItem("offline_dark_mode");
            if (localeOffline || themeOffline) {
              const preferences: { dark_mode?: boolean; locale?: string } = {};
              if (themeOffline) {
                preferences["dark_mode"] = themeOffline === "true";
                if (preferences["dark_mode"]) {
                  setThemeType(ThemeType.dark);
                } else {
                  setThemeType(ThemeType.light);
                }
                removeLocalStorageItem("offline_dark_mode");
              }
              if (localeOffline) {
                preferences["locale"] = localeOffline;
                setLocale(localeOffline, document.documentElement.dir);
                removeLocalStorageItem("offline_locale");
              }
              await axiosInstance.patch("/api/browser_preferences", preferences);
            } else {
              if (preferences?.locale) {
                setLocale(preferences.locale, document.documentElement.dir);
              }
  
              if (preferences?.dark_mode && preferences.dark_mode === true) {
                setThemeType(ThemeType.dark);
              } else {
                setThemeType(ThemeType.light);
              }
            }
          }
  
          // session is now valid
          setSessionExpired(false);
  
          // make sure that passThrough is reset regardless if a passthrough was  used
          removeLocalStorageItem("pass_through_path");
        })
        .catch((error: any) => {
          console.error("useAuthentication | ERROR", error);
          setHasError(true);
        });
    }
  };

  return {
    authenticate,
    hasError,
  };
};

export default useAuthentication;
