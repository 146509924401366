const NAMESPACE = "celsius_by_seekops";

type StorageKey =
  | "abilities"
  | "companies"
  | "company"
  | "company_name"
  | "emissions_preferences"
  | "EULA"
  | "flightpath_preferences"
  | "i18n"
  | "JWT"
  | "JWTr"
  | "offline_locale"
  | "offline_dark_mode"
  | "pass_through_path"
  | "refresh_expiry"
  | "site_id"
  | "theme"
  | "user"
  | "user_details"
  | "user_units"
  | "reauth_required";

/**
 * Get the namespace object from localStorage
 * @returns The namespace object or an empty object if not found
 */
const getNamespaceObject = (): Record<string, any> => {
  const serialized = localStorage.getItem(NAMESPACE);
  return serialized ? JSON.parse(serialized) : {};
};

/**
 * Save the namespace object to localStorage
 * @param obj - The namespace object to save
 */
const saveNamespaceObject = (obj: Record<string, any>): void => {
  localStorage.setItem(NAMESPACE, JSON.stringify(obj));
};

/**
 * Set an item in the namespace object in localStorage
 * @param key - The key under which the value is stored
 * @param value - The value to store
 */
export const setLocalStorageItem = (key: StorageKey, value: any): void => {
  try {
    const namespaceObject = getNamespaceObject();
    namespaceObject[key] = value;
    saveNamespaceObject(namespaceObject);
  } catch (error) {
    console.error(`Error setting item in localStorage: ${error}`);
  }
};

/**
 * Get an item from the namespace object in localStorage
 * @param key - The key of the item to retrieve
 * @returns The retrieved value or null if the key does not exist
 */
export const getLocalStorageItem = (key: StorageKey): any | null => {
  try {
    const namespaceObject = getNamespaceObject();
    return namespaceObject[key] ?? null;
  } catch (error) {
    console.error(`Error getting item from localStorage: ${error}`);
    return null;
  }
};

/**
 * Remove an item from the namespace object in localStorage
 * @param key - The key of the item to remove
 */
export const removeLocalStorageItem = (key: StorageKey): void => {
  try {
    const namespaceObject = getNamespaceObject();
    delete namespaceObject[key];
    saveNamespaceObject(namespaceObject);
  } catch (error) {
    console.error(`Error removing item from localStorage: ${error}`);
  }
};

/**
 * Clear all items in the namespace from localStorage
 */
export const clearLocalStorageNamespace = (): void => {
  try {
    localStorage.removeItem(NAMESPACE);
  } catch (error) {
    console.error(`Error clearing namespace in localStorage: ${error}`);
  }
};

/**
 * Clear specific keys in the namespace from localStorage
 * @param keys - An array of keys to remove
 */
export const clearLocalStorageNamespaceByKeys = (keys: StorageKey[]): void => {
  try {
    const namespaceObject = getNamespaceObject();
    keys.forEach((key) => {
      delete namespaceObject[key];
    });
    saveNamespaceObject(namespaceObject);
  } catch (error) {
    console.error(`Error clearing specific keys in namespace from localStorage: ${error}`);
  }
};
